import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "132",
  height: "25",
  fill: "none",
  viewBox: "0 0 132 25"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#FDE34F",
      "fill-rule": "evenodd",
      d: "m76.645 16.103 1.027-5.936 10.678.01-3.059 13.149c-4.96 1.969-11.792 2.883-16.021-1.172-4.805-4.605-3.704-13.386.493-18.043C72.112 1.502 75.539 0 79.842 0c2.418 0 5.224.41 8.508 2.66l.318.219-3.105 5.408-.368-.257c-1.677-1.176-3.603-1.763-5.91-1.763-1.59 0-3.138.62-4.29 1.885-1.151 1.264-1.934 3.21-1.934 5.907 0 1.572.599 3.019 1.746 3.854 1.105.807 3.21 1.082 5.196.285l.617-2.095zM12.705.392l1.387 12.654L19.772.392h8.775l-4.169 24.025h-6.361l1.727-9.896-4.703 9.896H9.347L8.103 14.381 6.362 24.417H0L4.164.392zm16.533 24.025h6.818L40.257.392h-6.85zM52.4.392l3.7 11.632L58.098.392h6.688l-4.169 24.025h-7.283l-3.662-11.413-1.967 11.413h-6.661L45.214.392zm48.121 0-12.567 24.02h6.795l2.027-4.138h6.679l.59 4.139h6.569L106.551.392zm-1.11 13.345 2.372-4.825.71 4.825zm12.82-7.074 1.096-6.271h17.777l-1.096 6.271h-5.229l-3.109 17.754h-6.818l3.073-17.754z",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }